import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/PageTemplate/index.ts";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const CharacterMap = makeShortcode("CharacterMap");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Yesanēchi Characters`}</h2>
    <p><strong parentName="p">{`Click character to copy to clipboard.`}</strong></p>
    <CharacterMap mdxType="CharacterMap" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      